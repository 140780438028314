/* eslint-disable */
// @ts-check
import { useHot } from "/@/kernel-mn/stores/useHot";


// 事件名
// af_login  登录
// af_logout  登出
// af_trigger 点击（提交）充值
// af_click_registration 点击注册
// af_complete_registration 完成注册
// af_click_withdraw  点击提现
// af_recharge 充值成功[首充复充都调用]
// af_firstrecharge  首充成功
// af_purchase  首充成功
// af_currency  充值币种 af_revenue   充值金额
export const recordAFEvent = (eventName, eventValue = {}) => {
  const url = 'af-event://inappevent?eventName=' + eventName + '&eventValue=' + JSON.stringify(eventValue);
  console.log(eventName, '<====>', JSON.stringify(eventValue));
  // @ts-ignore
  const jsBridge = window.jsBridge || null;
  if (jsBridge && jsBridge.postMessage) {
    // @ts-ignore
    jsBridge?.postMessage(eventName, JSON.stringify(eventValue));
    return;
  }
  var iframe = document.createElement('IFRAME');
  iframe.setAttribute('src', url);
  document.documentElement.appendChild(iframe);
  iframe.parentNode.removeChild(iframe);
  iframe = null;
};

// login 登录
// logout 登出
// registerClick 点击注册
// register 完成注册
// rechargeClick 充值提交成功 充值的钱 "{'amount':11}"
// firstrecharge 首充成功 "{'amount': 11}"
// recharge 复充成功 "{'amount': 11}"
// withdrawClick 提现提交成功

// fb埋点事件 - 14-1
// login 登录 'login','{success: 1}'
// logout 退出 'logout','{success: 1}'
// register 注册 'register','{success: 1}' & fbq('track', 'CompleteRegistration')
// charge 充值被点击 'rechargeClick','{success: 1}'
// chargeSuccess 充值成功
//  # 首充 - eventValue.isFirstDeposit
//  == 1 => 'firstrecharge', '{amount: eventValue.amount, currency: eventValue.currency, isFirst: 1, success: 1}'
//  == 1 => fbq('track', 'Purchase', {currency: eventValue.currency, value: eventValue.amount});
//  != 1 => 'recharge', '{amount: eventValue.amount, currency: eventValue.currency, isFirst: 0, success: 1}'
export const FBQRecordEvent = (eventName, eventValue: any = {}) => {
  // console.log(eventName, eventValue);
  const { hotData: { tenantInfo } } = useHot();
  // @ts-ignore
  const jsBridge = window.jsBridge || null;
  let sendValue = { ...eventValue, success: 1 };
  if (jsBridge && jsBridge.postMessage) {
    if (eventName === 'charge') { // 充值被点击
      jsBridge.postMessage('rechargeClick', JSON.stringify(sendValue));
    } else if (eventName === 'chargeSuccess') { // 充值成功
      if (eventValue.isFirstDeposit) { // 首充
        jsBridge.postMessage('firstrecharge', JSON.stringify({ ...sendValue, currency: sendValue.currency, isFirst: 1 }));
      } else { // 复充
        jsBridge.postMessage('recharge', JSON.stringify({ ...sendValue, currency: sendValue.currency, isFirst: 0 }));
      }
    } else { // 其他事件
      jsBridge.postMessage(eventName, JSON.stringify(sendValue));
    }
  }
  try {
    // @ts-ignore
    let fbq = window.fbq || undefined;
    // @ts-ignore
    const FBQEvent = window.FBQEvent || null;
    // 基本代码有加载
    if (fbq) {
      let { pixelInitializationTime } = fbq.getState() // pixelInitializationTime=-1初始化失败了
      let sendvalue = { ...eventValue, currency: eventValue.currency || tenantInfo.value.area, value: eventValue.amount || 0 };
      if (pixelInitializationTime > 0) {
        if (eventName === 'register') {
          FBQEvent && FBQEvent.register(eventName, sendvalue); // 注册
        } else if (eventName === 'charge') { // 充值被点击
          FBQEvent && FBQEvent.rechargeClick('rechargeClick', sendvalue); // 充值被点击
        } else if (eventName === 'chargeSuccess') {
          if (eventValue.isFirstDeposit) { // 首充
            FBQEvent && FBQEvent.firstrecharge('firstrecharge', sendvalue)
          }
        } else if (eventName === 'login') {
          FBQEvent && FBQEvent.login('login', sendvalue); // 登录
        }
      } else {
        // 如果fbq报错则重新初始化 执行
        // @ts-ignore
        let fbqinit = window.fbqinit || null;
        if (fbqinit) {
          fbqinit((pixid) => {
            fbq('init', pixid);
            fbq('track', 'PageView');
            setTimeout(() => {
              if (eventName === 'register') {
                FBQEvent && FBQEvent.register(eventName, sendvalue); // 注册
              } else if (eventName === 'charge') { // 充值被点击
                FBQEvent && FBQEvent.rechargeClick('rechargeClick', sendvalue); // 充值被点击
              } else if (eventName === 'chargeSuccess') {
                if (eventValue.isFirstDeposit) { // 首充
                  FBQEvent && FBQEvent.firstrecharge('firstrecharge', sendvalue)
                }
              } else if (eventName === 'login') {
                FBQEvent && FBQEvent.login('login', sendvalue); // 登录
              }
            }, 1000)
          });
        }
      }
    }
  } catch (e) {
    console.error(e);
  };
  // kwaiq 链接埋点逻辑总和 1、部署kwaiq到html 2.后台找到域名将自定义代码插入到底部代码 3.这里调用自定义代码
  // view 页面查看
  // login 登录 'login','{success: 1}'
  // logout 退出 'logout','{success: 1}'
  // register 注册 'register','{success: 1}' & fbq('track', 'CompleteRegistration')
  // charge 充值被点击 'rechargeClick','{success: 1}'
  // chargeSuccess 充值成功
  // clickWithdraw 提现成功
  //  # 首充 - eventValue.isFirstDeposit = 1

  /* KWAIQEvent为插入变量 KWAIQEvent.事件名(事件名, 值)
  * EVENT_CONTENT_VIEW 页面被查看 kwaiq.instance('pixel id').track('contentView')
  * EVENT_SUBSCRIBE 登录 kwaiq.instance('pixel id').track('subscribe')
  * EVENT_COMPLETE_REGISTRATION 注册 kwaiq.instance('pixel id').track('completeRegistration')
  * EVENT_ADD_PAYMENT_INFO 充值被点击 kwaiq.instance('pixel id').track('addPaymentInfo', {value: 9.2, currency: "USD"})
  * EVENT_PURCHASE 充值成功 kwaiq.instance('pixel id').track('purchase', {value: 9.2, currency: "USD"})
  * EVENT_FIRST_DEPOSIT 首充成功 kwaiq.instance('pixel id').track('firstDeposit', {value: 9.2, currency: "USD"})
  * EVENT_LOAN_APPLICATION 提现 kwaiq.instance('pixel id').track('loanApplication', {value: 9.2, currency: "USD"})
  * */
  try {
    // @ts-ignore
    const KWAIQEvent = window.KWAIQEvent || null;
    if (KWAIQEvent) {
      let sendvalue = { ...eventValue, currency: eventValue.currency || tenantInfo.value.area, value: eventValue.amount || 1 };
      if (eventName === 'charge') { // 充值被点击
        KWAIQEvent.rechargeClick && KWAIQEvent.rechargeClick('rechargeClick', sendvalue);
      } else if (eventName === 'chargeSuccess') { // 充值成功
        if (eventValue.isFirstDeposit) { // 首充
          KWAIQEvent.firstrecharge && KWAIQEvent.firstrecharge('firstrecharge', sendvalue);
        } else { // 复充
          KWAIQEvent.recharge && KWAIQEvent.recharge('recharge', sendvalue);
        }
      } else { // 其他事件
        KWAIQEvent[eventName] && KWAIQEvent[eventName](eventName, sendvalue)
      }
    }
  } catch (e) {
    console.error(e);
  }
};
